import React from "react";
import ParkIcon from "@mui/icons-material/Park";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import EventSeatIcon from "@mui/icons-material/EventSeat";
import AlignVerticalBottomIcon from "@mui/icons-material/AlignVerticalBottom";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import StairsIcon from "@mui/icons-material/Stairs";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import EngineeringIcon from "@mui/icons-material/Engineering";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import SignalCellularOffIcon from "@mui/icons-material/SignalCellularOff";

export const iconsMap = {
  "Σκουπίδια/αντικείμενα": <DeleteSweepIcon />,
  Οχήματα: <DirectionsCarIcon />,
  Εμπορεύματα: <Inventory2Icon />,
  "Τραπεζο-καθίσματα": <EventSeatIcon />,
  Δέντρο: <ParkIcon />,
  Στύλος: <AlignVerticalBottomIcon />,
  "Μόνιμες κατασκευές": <MapsHomeWorkIcon />,
  "Άλλο εμπόδιο": <ContactSupportIcon />,
  "Σκάλες χωρίς ράμπα": <StairsIcon />,
  "Κακή επιφάνεια βάδισης": <DirectionsWalkIcon />,
  Εργοτάξιο: <EngineeringIcon />,
  "Έλλειψη ράμπας": <SignalCellularOffIcon />,
  SignalCellular4BarIcon: <SignalCellular4BarIcon />,
};
