import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import GoogleIcon from "@mui/icons-material/Google";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import LogoutIcon from "@mui/icons-material/Logout";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { Menu, MenuItem } from "@mui/material";
import { loginGoogle, logout } from "../config/fetcher";

export default function Header({ user, setUser }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Box sx={{ zIndex: 2, position: "relative" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          height: 40,
          padding: "0px 10px",
        }}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <IconButton onClick={handleClick}>
          <MenuIcon />
        </IconButton>
      </Box>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#282c34",
          },
          li: {
            display: "flex",
            gap: "12px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            if (!user) loginGoogle();
          }}
        >
          {user ? (
            <>
              <img
                referrerPolicy="no-referrer"
                src={user.thumbnail}
                style={{ width: "22px", height: "22px", borderRadius: "100%" }}
              ></img>
              <span>{user.name}</span>
            </>
          ) : (
            <>
              <GoogleIcon sx={{ width: "20px" }} />
              <span>Σύνδεση</span>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <InfoIcon sx={{ width: "20px" }} />
          <span>Οδηγίες</span>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <HelpIcon sx={{ width: "20px" }} />
          <span>Επεξήγηση συμβόλων</span>
        </MenuItem>
        {user && (
          <MenuItem onClick={() => logout().then(() => setUser(undefined))}>
            <LogoutIcon sx={{ width: "20px" }} />
            <span>Αποσύνδεση</span>
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
};
