/* eslint-disable */

import React, { useEffect, useRef, useState } from "react";
import GpsFixedIcon from "@mui/icons-material/GpsFixed";
// openlayers
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
import Map from "ol/Map";
import View from "ol/View";
import XYZ from "ol/source/XYZ";
// import { transform } from "ol/proj";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import PropTypes from "prop-types";
// import { toStringXY } from "ol/coordinate";
import useResizeObserver from "@react-hook/resize-observer";
import Geolocation from "ol/Geolocation";

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Circle as CircleStyle, Fill, Stroke, Style, Text } from "ol/style";
import { Cluster, OSM, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import { boundingExtent } from "ol/extent";
import ReportPanel from "./ReportPanel";

const useSize = (target) => {
  const [size, setSize] = React.useState();
  React.useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect());
  }, [target]);

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
};

function MapWrapper(props) {
  const trackGPS = useRef(false);
  const mapElement = useRef();
  const geolocation = useRef();
  const styleCache = useRef({});
  const source = useRef();
  const highlight = useRef();
  const uvStyleCache = useRef({});
  const uvSource = useRef({});
  const size = useSize(mapElement);

  // initialize map on first render - logic formerly put into componentDidMount
  const handleResize = () => {
    props.map?.updateSize();
  };

  useEffect(() => {
    handleResize();
  }, [size]);

  useEffect(() => {
    if (!props.map || !props?.reports?.length) return;
    source.current.clear();
    uvSource.current.clear();
    const features = [];
    const uvFeatures = [];
    props.reports.forEach((report) => {
      var oFeature = new Feature({
        geometry: new Point([report.location.x, report.location.y]),
        meta: {
          report,
        },
      });
      oFeature.data = { report };
      if (report.votes < 3) {
        return uvFeatures.push(oFeature);
      }
      features.push(oFeature);
    });

    source.current.addFeatures(features);
    uvSource.current.addFeatures(uvFeatures);
  }, [props.reports, props.map]);

  useEffect(() => {
    source.current = new VectorSource({
      features: [],
    });
    uvSource.current = new VectorSource({
      features: [],
    });
    const clusterSource = new Cluster({
      distance: parseInt(30, 10),
      minDistance: parseInt(30, 10),
      source: source.current,
    });
    const uvClusterSource = new Cluster({
      distance: parseInt(30, 10),
      minDistance: parseInt(30, 10),
      source: uvSource.current,
    });
    const clusters = new VectorLayer({
      source: clusterSource,
      style: function (feature) {
        const size = feature.get("features")?.length || 10;
        let style = styleCache.current[size];
        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: 10,
              stroke: new Stroke({
                color: "#fff",
              }),
              fill: new Fill({
                color: "#d7301f",
              }),
            }),
            text: new Text({
              text: size.toString(),
              fill: new Fill({
                color: "#fff",
              }),
            }),
          });
          styleCache.current[size] = style;
        }
        return style;
      },
    });
    const uvClusters = new VectorLayer({
      source: uvClusterSource,
      style: function (feature) {
        const size = feature.get("features")?.length || 10;
        let style = uvStyleCache.current[size];
        if (!style) {
          style = new Style({
            image: new CircleStyle({
              radius: 10,
              stroke: new Stroke({
                color: "#fff",
              }),
              fill: new Fill({
                color: "#d7301f67",
              }),
            }),
            text: new Text({
              text: size.toString(),
              fill: new Fill({
                color: "#fff",
              }),
            }),
          });
          uvStyleCache.current[size] = style;
        }
        return style;
      },
    });
    const highlightStyle = new Style({
      image: new CircleStyle({
        radius: 15,
        stroke: new Stroke({
          color: "orange",
        }),
        fill: new Fill({
          color: "orange",
        }),
      }),
    });
    const featureOverlay = new VectorLayer({
      source: new VectorSource(),
      style: highlightStyle,
    });
    // create map
    const initialMap = new Map({
      target: mapElement.current,
      layers: [
        // Google Maps Terrain
        new TileLayer({
          source: new XYZ({
            url: "http://mt0.google.com/vt/hl=en&x={x}&y={y}&z={z}",
          }),
        }),
        uvClusters,
        clusters,
        featureOverlay,
      ],
      view: new View({
        projection: "EPSG:3857",
        center: props.center,
        zoom: 15,
        enableRotation: false,
      }),
      controls: [],
    });

    window.map = initialMap;

    const displayFeatureInfo = function (pixel) {
      Promise.all([
        uvClusters.getFeatures(pixel),
        clusters.getFeatures(pixel),
      ]).then(function ([features1, features2]) {
        const features = [...features1, ...features2];
        const feature = features.length ? features[0] : undefined;
        if (features.length) {
          if (features[0].values_.features.length > 1) {
            props.setSelectedReport(
              features[0].values_.features.map((d) => d.data.report)
            );
            return;
          } else {
            props.setSelectedReport([
              features[0].values_.features[0].data.report,
            ]);
          }
        } else {
          props.setSelectedReport(null);
        }
        if (feature !== highlight.current) {
          if (highlight.current) {
            featureOverlay.getSource().clear();
          }
          if (feature) {
            featureOverlay.getSource().addFeature(feature);
          }
          highlight.current = feature;
        }
      });
      uvClusters.getFeatures(pixel).then(function (features) {
        const feature = features.length ? features[0] : undefined;
        if (features.length) {
          if (features[0].values_.features.length > 1) {
            props.setSelectedReport(
              features[0].values_.features.map((d) => d.data.report)
            );
            return;
          } else {
            props.setSelectedReport([
              features[0].values_.features[0].data.report,
            ]);
          }
        } else {
          props.setSelectedReport(null);
        }
        if (feature !== highlight.current) {
          if (highlight.current) {
            featureOverlay.getSource().clear();
          }
          if (feature) {
            featureOverlay.getSource().addFeature(feature);
          }
          highlight.current = feature;
        }
      });
    };
    initialMap.on("moveend", function (e) {
      props.setCenter(initialMap.getView().getCenter());
    });
    initialMap.on("click", function (evt) {
      if (evt.dragging) {
        return;
      }

      const pixel = map.getEventPixel(evt.originalEvent);
      displayFeatureInfo(pixel);
    });
    geolocation.current = new Geolocation({
      // enableHighAccuracy must be set to true to have the heading value.
      trackingOptions: {
        enableHighAccuracy: true,
      },
      projection: initialMap.getView().getProjection(),
    });
    // handle geolocation error.
    geolocation.current.on("error", function (error) {
      console.error(error);
    });

    geolocation.current.on("change:position", function () {
      if (!trackGPS.current) return;

      const coordinates = geolocation.current.getPosition();
      if (coordinates) {
        initialMap.getView().setCenter(coordinates);
        initialMap.getView().setZoom(20);
        trackGPS.current = false;
      }
    });
    // set map onclick handler
    // save map and vector layer references to state
    props.setMap(initialMap);
  }, []);

  // render component
  return (
    <Box
      sx={{
        width: "100%",
        flex: 1,
        overflow: "hidden",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {props.selectedReport && (
        <ReportPanel
          setReports={props.setReports}
          onClose={() => props.setSelectedReport(null)}
          report={props.selectedReport}
          reports={props.reports}
        />
      )}
      <Box
        ref={mapElement}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      ></Box>
      <IconButton
        sx={{
          position: "absolute",
          top: 20,
          right: 10,
          color: "#282c34",
          backgroundColor: "white !important",
          zIndex: 1,
        }}
        onClick={() => {
          trackGPS.current = true;
          geolocation.current.setTracking(true);
        }}
      >
        <GpsFixedIcon />
      </IconButton>
      <LocationSearchingIcon
        sx={{
          position: "absolute",
          background: "transparent",
          pointerEvents: "none",
          color: "red",
        }}
      />
    </Box>
  );
}
MapWrapper.propTypes = {
  setMap: PropTypes.func,
  map: PropTypes.object,
  selectedReport: PropTypes.array,
  setSelectedReport: PropTypes.func,
};
export default MapWrapper;
