import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { Icon, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { iconsMap } from "../config/iconsMap";
import { createVote } from "../config/fetcher";

// const User = () => {
//   return (
//     <Box
//       sx={{
//         display: "flex",
//         gap: "8px",
//         fontSize: "12px",
//         flex: 1,
//         justifyContent: "space-between",
//         width: "100%",
//         alignItems: "end",
//       }}
//     >
//       <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
//         <span>{record.user_name}</span>
//         <img
//           referrerPolicy="no-referrer"
//           src={record.user_thumbnail}
//           style={{
//             width: "22px",
//             height: "22px",
//             borderRadius: "100%",
//           }}
//         ></img>
//       </Box>
//     </Box>
//   );
// };
export default function ReportPanel({ report, onClose }) {
  const [stateReport, setStateReport] = useState(report);
  useEffect(() => {
    setStateReport(report);
  }, [report]);
  return (
    <Box
      sx={{
        width: "calc(100% - 45px)",
        background: "white",
        borderRadius: "8px",
        position: "absolute",
        zIndex: "2",
        color: "black",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        padding: report.length > 1 ? "0px 12px 12px" : "12px",
        margin: "12px",
        maxHeight: "calc(100% - 35px)",
        overflow: "auto",
        bottom: "0px",
        minHeight: "65px",
      }}
    >
      <Box
        sx={
          report.length > 1
            ? {
                position: "sticky",
                top: "0px",
                display: "flex",
                width: "calc(100% + 10px)",
                justifyContent: "flex-end",
                background: "white",
              }
            : {
                position: "absolute",
                right: "0px",
                top: "0px",
                display: "flex",
                width: "100%",
                justifyContent: "flex-end",
              }
        }
      >
        <IconButton
          sx={{
            color: "black",
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {stateReport.map((record, index) => {
        const isMulti = report.length > 1;
        const isLast = index === report.length - 1;
        return (
          <Box
            key={record.id}
            sx={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              width: "100%",
              borderBottom: isMulti && !isLast ? "1px solid #dfdfdf" : "",
              padding: isMulti ? "10px 0px" : "",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
                marginTop: "5px",
              }}
            >
              <Icon sx={{ border: "1px solid", padding: "5px" }}>
                {iconsMap[record.obstacle_name]}
              </Icon>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <Typography component="h3">{record.obstacle_name}</Typography>
                <Typography component="h4" sx={{ fontSize: "14px" }}>
                  {record.accessibility_type_name}
                </Typography>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
              onClick={() => {
                createVote({
                  user_id: window.localStorage.userID,
                  report_id: record.id,
                }).then(({ data }) => {
                  const newStateReport = [...stateReport];
                  newStateReport.splice(index, 1, data[0]);
                  setStateReport(newStateReport);
                  report[index].voted = data[0].voted;
                  report[index].votes = data[0].votes;
                });
              }}
            >
              <ThumbUpIcon
                sx={{ fontSize: 16, fill: record.voted ? "#42a5f5" : "black" }}
              />
              <Typography sx={{ fontSize: 12 }}>{record.votes}</Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
ReportPanel.propTypes = {
  obstacle_name: PropTypes.string,
  accessibility_name: PropTypes.string,
  user_name: PropTypes.string,
  user_thumbnail: PropTypes.string,
  location: PropTypes.object,
  report: PropTypes.array,
  onClose: PropTypes.func,
  setReports: PropTypes.func,
  reports: PropTypes.array,
};
