import React, { useEffect, useState } from "react";
import MapWrapper from "./components/Map";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ControlPanel from "./components/ControlPanel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";

import Header from "./components/Header";
import Footer from "./components/Footer";
import { Box, styled } from "@mui/system";
import { getReports, getUser } from "./config/fetcher";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const AppStyled = styled(Box)(() => ({
  textAlign: "center",
  width: "100%",
  height: "100%",
  backgroundColor: "#282c34",
  color: "white",
  fontFamily: "Ubuntu",
}));

const MainStyled = styled(Box)(() => ({
  height: "calc(100% - 60px)",
  display: "flex",
  flexDirection: "column",
}));

function App() {
  const [map, setMap] = useState();
  const [center, setCenter] = useState([2555108.6895, 4957400.2012]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [reports, setReports] = useState([]);
  const [user, setUser] = useState(null);
  const handleGetReports = (userId) => {
    getReports(userId).then(({ data }) => {
      setReports(data);
    });
  };
  useEffect(() => {
    getUser().then(({ data }) => {
      if (Object.keys(data || {}).length) {
        setUser(data);
        window.localStorage.setItem("userID", data.id);
      } else window.localStorage.userID ??= uuidv4();
      handleGetReports(window.localStorage.userID);
    });
  }, [user]);
  return (
    <ThemeProvider theme={darkTheme}>
      <AppStyled className="App">
        <Header user={user} setUser={setUser} />
        <MainStyled>
          <MapWrapper
            setMap={setMap}
            map={map}
            reports={reports}
            center={center}
            setReports={setReports}
            setCenter={setCenter}
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
          />
          <ControlPanel
            map={map}
            center={center}
            getReports={handleGetReports}
          />
        </MainStyled>
        <Footer />
        <ToastContainer />
      </AppStyled>
    </ThemeProvider>
  );
}

export default App;
