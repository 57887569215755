import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/system";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import {
  Button,
  FormHelperText,
  Icon,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import {
  createReport,
  getAccessibility,
  getObstacles,
} from "../config/fetcher";

const ControlPanelStyled = styled(Box)(() => ({
  flexDirection: "column",
  width: "100%",
  maxWidth: "100%",
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

function ControlPanel(props) {
  const [open, setOpen] = useState(false);
  const [obstacle, setObstacle] = useState("");
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState({});
  const [obstacles, setObstacles] = useState([]);
  const [accessibility, setAccessibility] = useState([]);
  const [selectedAccessibility, setSelectedAccessibility] = useState("");

  useEffect(() => {
    getObstacles().then(({ data }) => {
      setObstacles(data || []);
    });
    getAccessibility().then(({ data }) => {
      setAccessibility(data || []);
    });
  }, []);

  const checkForm = (data) => {
    const newErrors = {};
    if (!data.obstacle_id) newErrors.obstacle_id = true;
    if (!data.accessibility_type_id) newErrors.accessibility_type_id = true;
    if (isOtherObstacle() && !notes) newErrors.description = true;
    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return false;
    }
    setErrors({});
    return true;
  };
  const handleCreateReport = () => {
    if (!window.localStorage.userID) {
      window.localStorage.userID = uuidv4();
    }
    const data = {
      obstacle_id: obstacle,
      accessibility_type_id: selectedAccessibility,
      location: props.center.map((coord) => coord.toFixed(2)).join(", "),
      description: notes,
      reported_from_user_id: window.localStorage.userID,
    };
    const check = checkForm(data);
    if (!check) return;
    createReport(data).then(() => {
      setObstacle("");
      setSelectedAccessibility("");
      setNotes("");
      toast("Καταχωρήθηκε επιτυχώς!");
      props.getReports();
    });
  };
  const FormHelperTextStyled = styled(FormHelperText)(() => ({
    color: "#e16565",
  }));
  const isOtherObstacle = () => {
    return (
      obstacles.filter((item) => item.id === obstacle)[0]?.name ===
      "Άλλο εμπόδιο"
    );
  };
  return (
    <ControlPanelStyled>
      <Box
        sx={{
          width: "100%",
          height: "40px",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "row",
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        <Icon>{!open ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</Icon>
        <Typography variant="h6" component="h6" sx={{ paddingLeft: "10px" }}>
          Καταχώρηση εμποδίου
        </Typography>
      </Box>
      <Box
        sx={{
          maxHeight: open ? "40vh" : "0px",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          padding: open ? "20px 0px" : "0px",
          gap: "22px",
          alignItems: "flex-start",
          width: "calc(100% - 20px)",
          overflow: "auto",
          transition: "all ease .2s",
        }}
      >
        <FormControl sx={{ width: "100%" }}>
          <InputLabel size="small" id="demo-simple-select-label">
            Επιλέξτε είδος εμποδίου
          </InputLabel>
          <Select
            autoWidth
            size="small"
            variant={"outlined"}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={obstacle}
            required
            label={<span>Επιλέξτε είδος εμποδίου</span>}
            onChange={(val) => {
              setObstacle(val.target.value);
            }}
          >
            {obstacles
              .filter((item) => item.name !== "Άλλο εμπόδιο")
              .map((item, i) => (
                <MenuItem key={`obstacle-${i}`} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            {obstacles
              .filter((item) => item.name === "Άλλο εμπόδιο")
              .map((item, i) => (
                <MenuItem
                  key={`obstacle-${i}`}
                  sx={{ borderTop: "1px solid #eee" }}
                  value={item.id}
                >
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          {errors.obstacle_id && (
            <FormHelperTextStyled>This is required!</FormHelperTextStyled>
          )}
        </FormControl>
        {isOtherObstacle() && (
          <>
            <FormControl sx={{ width: "100%" }}>
              <TextField
                label="Περιγραφή εμποδίου"
                variant="outlined"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </FormControl>
            {errors.description && (
              <FormHelperTextStyled>This is required!</FormHelperTextStyled>
            )}
          </>
        )}
        <FormControl sx={{ width: "100%" }}>
          <InputLabel size="small" id="demo-simple-select-label">
            Επιλέξτε προσβασιμότητα
          </InputLabel>
          <Select
            autoWidth
            size="small"
            variant={"outlined"}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedAccessibility}
            label={<span>Επιλέξτε προσβασιμότητα</span>}
            onChange={(val) => {
              setSelectedAccessibility(val.target.value);
            }}
            required
          >
            {accessibility.map((item, i) => (
              <MenuItem key={`obstacle-${i}`} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {errors.accessibility_type_id && (
            <FormHelperTextStyled>This is required!</FormHelperTextStyled>
          )}
        </FormControl>
        <FormControl sx={{ width: "100%" }}>
          <Button variant="contained" onClick={handleCreateReport}>
            Καταχωρηση
          </Button>
        </FormControl>
      </Box>
    </ControlPanelStyled>
  );
}
ControlPanel.propTypes = {
  map: PropTypes.any,
  center: PropTypes.array,
  getReports: PropTypes.func,
};

export default ControlPanel;
