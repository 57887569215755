import { Box } from "@mui/system";
import React from "react";
export default function Footer() {
  return (
    <Box
      sx={{
        height: "20px",
        fontSize: "12px",
        display: "flex",
        alignItems: "center",
        padding: "0px 24px",
        color: "#999",
        fontFamily: "Arial",
        justifyContent: "center",
      }}
    >
      Copyright &copy; University of Macedonia {new Date().getFullYear()}
    </Box>
  );
}
