const baseUrl = process.env.REACT_APP_API_URL || "http://localhost:3001";
export const getObstacles = () =>
  fetch(`${baseUrl}/obstacles`, { method: "GET", credentials: "include" })
    .then((response) => response.json())
    .catch((e) => {
      console.error(e);
      return {};
    });
export const logout = () =>
  fetch(`${baseUrl}/logout`, { method: "POST", credentials: "include" })
    .then((response) => response.json())
    .catch((e) => {
      console.error(e);
      return {};
    });

export const loginGoogle = () =>
  window.location.replace(`${baseUrl}/auth/google`);

export const getAccessibility = () =>
  fetch(`${baseUrl}/accessibility`, { method: "GET", credentials: "include" })
    .then((response) => response.json())
    .catch((e) => {
      console.error(e);
      return {};
    });

export const getUser = () =>
  fetch(`${baseUrl}/user`, { method: "GET", credentials: "include" })
    .then((response) => response.json())
    .catch((e) => {
      console.error(e);
      return {};
    });
export const getReports = (userId) =>
  fetch(`${baseUrl}/reports?user_id=${userId}`, {
    method: "GET",
    credentials: "include",
  })
    .then((response) => response.json())
    .catch((e) => {
      console.error(e);
      return {};
    });

export const createReport = (data) =>
  fetch(`${baseUrl}/reports`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ data }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((e) => {
      console.error(e);
      return {};
    });
export const createVote = (data) =>
  fetch(`${baseUrl}/votes`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify({ data }),
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .catch((e) => {
      console.error(e);
      return {};
    });
